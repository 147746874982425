import React from 'react';

import Users from './views/users/Users';
import User from './views/users/User';
import Lotteries from './views/lottery/Lotteries';
import Lottery from './views/lottery/Lottery';
import Restrictions from './views/restrictions/Restrictions';
import Restriction from './views/restrictions/Restriction';
import Sale from './views/sales/Sale';
import Sales from "./views/sales/Sales";
import Movements from "./views/movements/Movements";
import Project from "./views/projects/Project";
import Projects from "./views/projects/Projects";
import Recharge from "./views/recharge/Recharge";
import RechargeApproval from "./views/recharge/RechargeApproval";
import {Role} from "./_helpers/role";
import ManageUserBalance from "./views/recharge/ManageUserBalance";
import EditProfile from "./views/users/EditProfile";
import Requests from "./views/requests/Requests";
import SaleRestrictions from "./views/restrictions/SaleRestrictions";
import SaleRestriction from "./views/restrictions/SaleRestriction";
import AutomaticRestriction from "./views/restrictions/AutomaticRestriction";
import AutomaticRestrictions from "./views/restrictions/AutomaticRestrictions";
import Chat from './views/chat/Chat';

const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/users', exact: true,  name: 'Usuarios', component: Users, roles: [Role.Manager, Role.Admin, Role.Supervisor] },
  { path: '/users/edit/:id', exact: true, name: 'Detalles de usuario', component: User, roles: [Role.Manager, Role.Admin, Role.Supervisor] },
  { path: '/users/editProfile/:id', exact: true, name: 'Detalles de usuario', component: EditProfile, roles: [Role.Manager, Role.Admin, Role.Supervisor, Role.Seller, Role.Donor] },
  { path: '/users/create', exact: false, name: 'Crear nuevo usuario', component: User, roles: [Role.Manager, Role.Admin, Role.Supervisor] },
  { path: '/lotteries', exact: true,  name: 'Sorteos', component: Lotteries, roles: [Role.Manager] },
  { path: '/lotteries/edit/:id', exact: true, name: 'Detalles del sorteo', component: Lottery, roles: [Role.Manager] },
  { path: '/lotteries/create', exact: false, name: 'Crear nuevo sorteo', component: Lottery, roles: [Role.Manager] },
  { path: '/restrictions', exact: true,  name: 'Sorteos', component: Restrictions, roles: [Role.Manager] },
  { path: '/restrictions/edit/:id', exact: true, name: 'Detalles del sorteo', component: Restriction, roles: [Role.Manager] },
  { path: '/restrictions/create', exact: false, name: 'Crear nuevo sorteo', component: Restriction, roles: [Role.Manager] },
  { path: '/automaticRestrictions', exact: true, name: 'Detalles de restricción automática', component: AutomaticRestrictions, roles: [Role.Manager] },
  { path: '/automaticRestrictions/edit/:id', exact: true, name: 'Detalles de restricción automática', component: AutomaticRestriction, roles: [Role.Manager] },
  { path: '/automaticRestrictions/create', exact: false, name: 'Crear restricción automática', component: AutomaticRestriction, roles: [Role.Manager] },

  { path: '/sale/restrictions', exact: true,  name: 'Restricciones de ventas', component: SaleRestrictions, roles: [Role.Manager] },
  { path: '/sale/restrictions/edit/:id', exact: true, name: 'Detalles del sorteo', component: SaleRestriction, roles: [Role.Manager] },
  { path: '/sale/restrictions/create', exact: false, name: 'Crear nuevo sorteo', component: SaleRestriction, roles: [Role.Manager] },
    
  { path: '/sales/', exact: false, name: 'Ventas', component: Sale, roles: [Role.Manager, Role.Admin, Role.Supervisor, Role.Seller, Role.Donor] },
  { path: '/list/', exact: false, name: 'Lista', component: Sales, roles: [Role.Manager, Role.Admin, Role.Supervisor, Role.Seller, Role.Donor] },
  { path: '/movements/', exact: false, name: 'Movimientos', component: Movements, roles: [Role.Manager] },
  { path: '/projects', exact: true, name: 'Proyectos', component: Projects, roles: [Role.Manager] },
  { path: '/projects/edit/:id', exact: true, name: 'Detalles del proyecto', component: Project, roles: [Role.Manager] },
  { path: '/projects/create', exact: false, name: 'Crear nuevo proyecto', component: Project, roles: [Role.Manager] },
  { path: '/recharges', exact: true,  name: 'Recargar', component: Recharge, roles: [Role.Manager, Role.Admin, Role.Supervisor, Role.Seller, Role.Donor] },
  { path: '/rechargeApproval', exact: true,  name: 'Aprobación de recargas', component: RechargeApproval, roles: [Role.Manager] },
  { path: '/manageUserBalance', exact: true,  name: 'Saldo de mi equipo', component: ManageUserBalance, roles: [Role.Manager] },
  { path: '/requests', exact: true,  name: 'Solicitudes de acreditar a cta.', component: Requests, roles: [Role.Manager] },
  { path: '/chat', exact: true,  name: 'Chat', component: Chat, roles: [Role.Manager] },
];

export default routes;
