import React from 'react'
import {
  CBadge,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImg
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {Link, useHistory} from "react-router-dom";
import {authenticationService} from "../_services/authentication.service";
import {Role} from "../_helpers/role";
import AuthProvider from "../AuthProvider";

const TheDonationMenu = ({...props}) => {
  const history = useHistory();
  if (!props.config) {
    props.config = "14.4px";
  }
  return (
    <CDropdown
      inNav
      className="c-header-nav-items mx-2"
      direction="down"
    >
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <div className="c-avatar" style={{"fontSize": props.config.size}}>
          Donación
        </div>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <AuthProvider roles={[Role.Admin, Role.Supervisor, Role.Seller, Role.Donor]}>
          <CDropdownItem onClick={() => {
            history.push("/sales");
          }}>
            <CIcon name="cil-user" className="mfe-2" />{authenticationService.isDonor ? "Donar" : "Recolectar"}
          </CDropdownItem>
        </AuthProvider>
        <AuthProvider roles={[Role.Manager]}>
          <CDropdownItem onClick={() => {
            history.push("/movements");
          }}>
            <CIcon name="cil-file" className="mfe-2" />Movimientos
          </CDropdownItem>
        </AuthProvider>
        <AuthProvider roles={[Role.Manager]}>
          <CDropdownItem onClick={() => {
            history.push("/rechargeApproval");
          }}>
            <CIcon name="cil-applications-settings" className="mfe-2" />Aprobación de recargas
          </CDropdownItem>
        </AuthProvider>
        <AuthProvider roles={[Role.Manager, Role.Admin, Role.Supervisor, Role.Seller, Role.Donor]}>
          <CDropdownItem onClick={() => {
            history.push("/list");
          }}>
            <CIcon name="cil-paperclip" className="mfe-2" />Lista
          </CDropdownItem>
        </AuthProvider>
      </CDropdownMenu>
    </CDropdown>
  )
}

export default TheDonationMenu
