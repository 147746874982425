import React, {useEffect, useState} from 'react';
import {
    CCard,
    CButton,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CContainer,
    CLabel,
    CDataTable,
    CModal,
    CModalHeader,
    CModalBody,
    CImg,
    CModalFooter,
    CSwitch,
    CModalTitle,
    CForm,
    CFormGroup, CInput, CCardFooter, CTextarea
} from '@coreui/react'
import NumberTable from "../../components/NumberTable";
import Select from "react-select";
import {crudService} from "../../_services/crud.service";
import es from "date-fns/locale/es";
import DatePicker from "react-datepicker";
import {selectHelper} from "../../_helpers/select.helper";
import {Role} from "../../_helpers/role";
import AuthProvider from "../../AuthProvider";
import {authenticationService} from "../../_services/authentication.service";
import CIcon from "@coreui/icons-react";
import {alertService} from "../../_services/alert.service";

const salesFields = [
    {key: 'bill', label: 'Factura', _style: {width: '20%'}},
    {key: 'amountFormatted', label: 'Monto', _style: {width: '10%'}},
    {key: 'client', label: 'Cliente', _style: {width: '20%'}},
    {key: 'sale', label: 'Venta', _style: {width: '15%'}},
    {key: 'date', label: 'Fecha de compra', _style: {width: '20%'}},
    {key: 'prize', label: 'Premio', _style: {width: '20%'}},
    {key: 'prizeApplied', label: 'Aplicado', _style: {width: '20%'}},
    {key: 'prizePaid', label: 'Pagado', _style: {width: '20%'}},
    {key: 'prizePaidComment', label: 'Comentarios del pago', _style: {width: '20%'}},
    {key: 'cancellationDate', label: 'Fecha de anulación', _style: {width: '20%'}},
    {key: 'cancel', label: '', _style: {width: '1%'}, sorter: false, filter: false },
    {key: 'show_details', label: '', _style: { width: '1%' }, sorter: false, filter: false}
]

const prizeFields = [
    {key: 'comment', label: 'Comentario', _style: {width: '30%'}},
    {key: 'paid', label: 'Pagado', _style: {width: '5%'}},
    {key: 'username', label: 'Usuario', _style: {width: '30%'}},
    {key: 'date', label: 'Fecha', _style: {width: '25%'}}
]

const Sales = () => {
    const [sales, setSales] = useState([]);
    const [prizeComments, setPrizeComments] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [salesAmount, setSalesAmount] = useState(0);
    const [selectedLottery, setSelectedLottery] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [lotteries, setLotteries] = useState([]);
    const [users, setUsers] = useState([]);
    const [shouldRefresh, setShouldRefresh] = useState(false);
    const [newBillNumber, setNewBillNumber] = useState(null);
    const [sourceImg, setSourceImg] = useState("");
    const [showReceipt, setShowReceipt] = useState(false);
    const [countryCurrencySign, setCountryCurrencySign] = useState("");
    const [modify, setModify] = useState(false);
    const [comments, setComments] = useState("");
    const [currentBill, setCurrentBill] = useState(null);
    
    function cancelSale(sale) {
        crudService.post('/sale/cancel',
            sale,
            () => {
                setShouldRefresh(!shouldRefresh);
            },
            'La venta se anuló correctamente.',
            'Ocurrió un error anulando la venta.');
    }

    useEffect(() => {
        async function fetchLotteries() {
            crudService.get(`lottery/getByCountryId`, (data) => {
                selectHelper.populateSelectControl(data, "id",
                    "description",
                    setLotteries);
            }, 'Ocurrió un error listando los sorteos.')
        }

        fetchLotteries();
    }, []);

    useEffect(() => {
        async function fetchUsers() {
            crudService.get('user/getAllUsers/true/00000000-0000-0000-0000-000000000000', (data) => {
                selectHelper.populateSelectControl(data, "id", "name", setUsers);
            }, 'Ocurrió un error listando los sorteos.');
        }

        fetchUsers();
    }, []);

    useEffect(() => {
        async function fetchCountryCurrencySign() {
            crudService.get('User/getCountryCurrencySign', (currencySign) => {
                setCountryCurrencySign(currencySign);
            }, 'Ocurrió un error obteniendo el símbolo de la moneda.')
        }

        fetchCountryCurrencySign();
    }, []);

    useEffect(() => {
        let totalAmount = 0;
        for (let i = 0; i < sales.length; i++) {
            totalAmount += sales[i].amount;
        }

        setSalesAmount(totalAmount);
    }, [sales]);

    useEffect(() => {
        async function fetchSales() {
            if (!selectedLottery || !selectedDate) {
                setSales([]);
                return;
            }
            
            crudService.getWithParams('sale/getsales', {
                "lotteryId": selectedLottery,
                "userId": selectedUser,
                year: selectedDate.getFullYear(),
                month: selectedDate.getMonth() + 1,
                day: selectedDate.getDate(),
            }, setSales, 'Ocurrió un error obteniendo las ventas.');
        }

        fetchSales();
    }, [selectedLottery, selectedUser, selectedDate, shouldRefresh]);

    useEffect(() => {
        async function fetchPrizeComments() {
            if (!currentBill) {
                setPrizeComments([])
                return;
            }

            crudService.get(`/sale/prizeComments/${currentBill}`, setPrizeComments, 'Ocurrió un error obteniendo los comentarios de la venta.');
        }

        fetchPrizeComments();
    }, [currentBill, modify]);

    const toggleStatus = (item) => {
        setCurrentBill(item.bill);
        setComments(item.prizePaidComment);
        setPrizeComments([]);
        setModify(!modify);
    };

    const handleSubmit = event => {
        event.preventDefault();

        const newData = sales.map((sale) => {
            if (sale.bill === currentBill) {
                return { ...sale, prizePaid: !sale.prizePaid };
            }
            return sale;
        });

        if (newData.length === 0) {
            return;
        }
        
        if (!currentBill) {
            return;
        }

        crudService.update('/sale/updatePrizePaid',
            {
                "lotteryId": selectedLottery,
                bill: currentBill,
                year: selectedDate.getFullYear(),
                month: selectedDate.getMonth() + 1,
                day: selectedDate.getDate(),
                prizePaid: newData[0].prizePaid,
                prizePaidComment: comments
            },
            () => {
                setShouldRefresh(!shouldRefresh);
                setCurrentBill(null);
                setComments("");
                setModify(!modify);
            },
            'La venta se actualizó.',
            'Ocurrió un error actualizando la venta.');
    }

    const renderRestrictionsForm = () => {
        return (
            <AuthProvider roles={[Role.Manager, Role.Admin, Role.Supervisor, Role.Seller, Role.Donor]}>
                <CContainer fluid>
                    <CRow>
                        <CCol lg={12}>
                            <CModal
                                show={showReceipt}
                                onClose={() => {
                                    setShowReceipt(!showReceipt);
                                }}>
                                <CModalHeader closeButton>Tiquete</CModalHeader>
                                <CModalBody>
                                    <CRow>
                                        <CCol lg={12} className="text-center">
                                            <CImg src={sourceImg} fluid className="img-thumbnail"></CImg>
                                        </CCol>
                                    </CRow>
                                </CModalBody>
                                <CModalFooter>
                                    <CButton color="primary" onClick={() => {
                                        window.open(`/sale/getSaleReceiptDownload/${newBillNumber}`, '_blank', 'noreferrer');
                                    }}>Imprimir</CButton>{' '}
                                    <CButton color="secondary" onClick={() => {
                                        setShowReceipt(!showReceipt);
                                    }}>Cerrar</CButton>
                                </CModalFooter>
                            </CModal>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol lg={12}>
                            <CModal
                                show={modify}
                                onClose={() => setModify(!modify)}
                                color="warning">
                                <CModalHeader closeButton>
                                    <CModalTitle>Confirmación</CModalTitle>
                                </CModalHeader>
                                <CModalBody>
                                    <CForm className="was-validated"
                                           onSubmit={handleSubmit}>
                                        <CFormGroup>
                                            <CLabel>¿Está seguro que desea actualizar la venta?</CLabel>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="comment">Comentarios</CLabel>
                                            <CTextarea
                                                rows="4"
                                                id="comment"
                                                placeholder="Comentarios"
                                                value={comments}
                                                autoComplete="off"
                                                onChange={(e) => {
                                                    setComments(e.target.value);
                                                }}/>
                                        </CFormGroup>
                                        <CCardFooter>
                                            <CButton type="submit" size="md" color="primary" className="mr-2"><CIcon
                                                name="cil-scrubber"/> Actualizar</CButton>
                                            <CButton color="secondary" onClick={() => {
                                                setModify(!modify)
                                            } }>Cancelar</CButton>
                                        </CCardFooter>
                                    </CForm>
                                    <CRow>
                                        <CCol lg={12}>
                                            <CDataTable
                                                items={prizeComments}
                                                fields={prizeFields}
                                                columnFilter
                                                noItemsView={{noResults: 'No hay comentarios', noItems: 'No hay comentarios'}}
                                                tableFilter={{placeholder: 'Buscar', label: 'Buscar:'}}
                                                footer
                                                itemsPerPageSelect={{label: 'Filas por página'}}
                                                itemsPerPage={5}
                                                hover
                                                sorter
                                                pagination
                                                scopedSlots={{
                                                    'paid':
                                                        (item, index) => {
                                                            return (
                                                                <td>
                                                                    {item.paid ? "Sí" : "No"}
                                                                </td>
                                                            )
                                                        },
                                                }}
                                            />
                                        </CCol>
                                    </CRow>
                                </CModalBody>
                            </CModal>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol lg={12}>
                            <CCard>
                                <CCardHeader>
                                    <strong>{'Lista'}</strong>
                                </CCardHeader>
                                <CCardBody className={"table-responsive"}>
                                    <CRow>
                                        <CCol lg={4}>
                                            <CLabel htmlFor="lottery">Sorteo</CLabel>
                                            <Select
                                                required
                                                value={lotteries.filter(option => option.value === selectedLottery)}
                                                onChange={(e) => {
                                                    setSelectedLottery(e.value);
                                                }}
                                                placeholder={"Seleccione un sorteo"}
                                                noOptionsMessage={() => "No hay sorteos"}
                                                options={lotteries}/>
                                        </CCol>
                                        <CCol lg={3}>
                                            <CLabel htmlFor="fecha">Fecha</CLabel>
                                            <DatePicker
                                                id="fecha"
                                                dateFormat="dd/MM/yyyy"
                                                selected={selectedDate}
                                                autoComplete="false"
                                                locale={es}
                                                onChange={(date) => {
                                                    setSelectedDate(date);
                                                }}
                                                className="form-control"
                                            />
                                        </CCol>
                                        <CCol lg={3}>
                                            <CLabel hidden={!authenticationService.isManager} htmlFor="seller">Recolector</CLabel>
                                            <Select
                                                className={authenticationService.isManager ? '' : 'd-none'}
                                                required
                                                defaultValue={selectedUser}
                                                onChange={(e) => {
                                                    setSelectedUser(e.value);
                                                }}
                                                placeholder={"Seleccione un recolector"}
                                                noOptionsMessage={() => "No hay recolectores"}
                                                options={users}/>
                                        </CCol>
                                        <CCol lg={2}>
                                            <CRow>
                                                <CCol lg={12}>
                                                    <CLabel>Total</CLabel>
                                                </CCol>
                                            </CRow>
                                            <CRow>
                                                <CCol lg={12}>
                                                    <CLabel><b>{countryCurrencySign}{salesAmount}</b></CLabel>
                                                </CCol>
                                            </CRow>
                                        </CCol>
                                    </CRow>
                                    <CRow className={"row-top"}>
                                        <CCol lg={12}>
                                            <NumberTable sales={sales}/>
                                        </CCol>
                                    </CRow>
                                </CCardBody>
                            </CCard>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol lg={12}>
                            <CCard>
                                <CCardHeader>
                                    <strong>{'Compras'}</strong>
                                </CCardHeader>
                                <CCardBody>
                                    <CDataTable
                                        items={sales}
                                        fields={salesFields}
                                        columnFilter
                                        noItemsView={{noResults: 'No hay compras', noItems: 'No hay compras'}}
                                        tableFilter={{placeholder: 'Buscar', label: 'Buscar:'}}
                                        footer
                                        itemsPerPageSelect={{label: 'Filas por página'}}
                                        itemsPerPage={5}
                                        hover
                                        sorter
                                        pagination
                                        scopedSlots={{
                                            'prizeApplied':
                                                (item, index) => {
                                                    return (
                                                        <td>
                                                            {item.prizeApplied ? "Sí" : "No"}
                                                        </td>
                                                    )
                                                },
                                            'prizePaid':
                                                (item, index) => {
                                                    return (
                                                        <td>
                                                            <CSwitch
                                                                color={'primary'}
                                                                checked={item.prizePaid}
                                                                labelOn={"Si"}
                                                                labelOff={"No"}
                                                                onChange={(e) => {
                                                                    toggleStatus(item);
                                                                }}
                                                            />
                                                        </td>
                                                    )
                                                },
                                            'cancel':
                                                (item, index) => {
                                                    return (
                                                        <td className="py-2">
                                                            <CButton
                                                                color="primary"
                                                                variant="outline"
                                                                shape="square"
                                                                size="sm"
                                                                onClick={() => {
                                                                    cancelSale(item);
                                                                }}
                                                            >
                                                                Anular
                                                            </CButton>
                                                        </td>
                                                    )
                                                },
                                            'show_details':
                                                (item, index)=>{
                                                    return (
                                                        <td className="py-2">
                                                            <CButton
                                                                color="primary"
                                                                variant="outline"
                                                                shape="square"
                                                                size="sm"
                                                                onClick={() => {
                                                                    setNewBillNumber(item.bill);
                                                                    crudService.get(`/sale/getSaleReceipt/${item.bill}`, (receipt) => {
                                                                        setSourceImg(`data:image/png;base64,${receipt}`);
                                                                        setShowReceipt(true);
                                                                    });
                                                                }}
                                                            >
                                                                {'Ver tiquete'}
                                                            </CButton>
                                                        </td>
                                                    )
                                                }
                                        }}
                                    />
                                </CCardBody>
                            </CCard>
                        </CCol>
                    </CRow>
                </CContainer>
            </AuthProvider>
        );
    }

    return (renderRestrictionsForm())
}

export default Sales