import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom';
import {
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CSubheader,
    CContainer,
    CForm,
    CInput,
    CImg,
    CInputGroup,
    CInputGroupPrepend,
    CInputGroupText,
    CRow, CLabel, CFormGroup, CInputRadio,
    CTextarea, CInvalidFeedback
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {authenticationService} from "../../../_services/authentication.service";
import {crudService} from "../../../_services/crud.service";
import {alertService} from "../../../_services/alert.service";
import '../../../scss/_login.scss';
import DatePicker from "react-datepicker";
import es from "date-fns/locale/es";


const Login = () => {
    const history = useHistory();
    const [login, setLogin] = useState({});
    const [donor, setDonor] = useState({});
    const [showDonor, setShowDonor] = useState(false);
    const [countryChunks, setCountryChunks] = useState([]);

    const chunkArray = (array, chunkSize) => {
        const chunks = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            chunks.push(array.slice(i, i + chunkSize));
        }
        return chunks;
    };

    useEffect(() => {
        async function fetchCountries() {
            crudService.get('/country', (data) => {
                const countries = chunkArray(data, 4);
                setCountryChunks(countries);
            }, 'Ocurrió un error listando los métodos de pago.');
        }

        fetchCountries();
    }, []);

    useEffect(() => {
        if (authenticationService.currentUserValue) {
            history.push('/');
        }
    }, []);

    const handleSubmit = event => {
        event.preventDefault();

        const form = event.currentTarget;

        if (form.checkValidity()) {
            authenticationService.login(login.username, login.password).then(user => {
                history.push('/list')
            });
        }
    }

    const donorSubmit = event => {
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity()) {
            if (donor.countryId === undefined) {
                alertService.error("Debe seleccionar un país.");
                return;
            }

            if (!donor.birth) {
                alertService.error("La fecha de nacimiento es requerida.");
                return;
            }
            
            crudService.post('/user/create-donor', 
                {
                    yearBirth: donor.birth.getFullYear(),
                    monthBirth: donor.birth.getMonth() + 1,
                    dayBirth: donor.birth.getDate(),
                    ...donor,
                },
                () => {
                    //alertService.success("Solicitud enviada correctamente. Su nombre de usuario y contraseña serán enviados a su correo electrónico.")
                    alertService.success("Solicitud enviada correctamente. Su nombre de usuario y contraseña serán el documento de identidad ingresado.")
                    setDonor({});
                    setShowDonor(false);
                },
                'Ocurrió un error enviando la solicitud.')
        }
    }

    return (
        <div className="c-app c-default-layout flex-row align-items-center">
            <CContainer>
                <CRow className="justify-content-center">
                    <CCol md="8">
                        <CCardGroup>
                            <CCard className="p-4 login-background">
                                <CCardBody>
                                    <div className="login">
                                        <CImg style={{"height": 145}}
                                              src={require("../../../assets/images/logo.png").default} fluid></CImg>
                                    </div>
                                    <CSubheader className="px-2 justify-content-center mx-auto row-bottom login-background">
                                        <ol className="border-0 c-subheader-nav m-0 px-0 px-lg-3">
                                            <li className="breadcrumb-item" role="presentation"><a
                                                href="https://www.fundeporte.org/anotaygana"
                                                target=" _blank">Nosotros</a></li>
                                            <li className="breadcrumb-item" role="presentation"><a
                                                href="https://www.facebook.com/anotayganatv"
                                                target=" _blank"># Ganador</a></li>
                                            <li className="breadcrumb-item" role="presentation"><a
                                                href="https://www.fundeporte.org/contactenos"
                                                target=" _blank">Contactenos</a></li>
                                        </ol>
                                    </CSubheader>
                                    <CRow>
                                        <CCol>
                                            <CForm className="row-bottom" onSubmit={handleSubmit}>
                                                <p className="text-size login-text-color">INICIAR SESIÓN</p>
                                                <CFormGroup row>
                                                    <CCol md="12">
                                                        <CInputGroup>
                                                            <CInputGroupPrepend>
                                                                <CInputGroupText>
                                                                    <CIcon name="cil-user"/>
                                                                </CInputGroupText>
                                                            </CInputGroupPrepend>
                                                            <CInput type="text"
                                                                    placeholder="Nombre de usuario"
                                                                    autoComplete="off"
                                                                    onChange={(e) => {
                                                                        setLogin({...login, username: e.target.value});
                                                                    }}/>
                                                        </CInputGroup>
                                                    </CCol>
                                                </CFormGroup>
                                                <CFormGroup row>
                                                    <CCol md="12">
                                                        <CInputGroup className="mb-2">
                                                            <CInputGroupPrepend>
                                                                <CInputGroupText>
                                                                    <CIcon name="cil-lock-locked"/>
                                                                </CInputGroupText>
                                                            </CInputGroupPrepend>
                                                            <CInput type="password"
                                                                    placeholder="Contraseña"
                                                                    autoComplete="off"
                                                                    onChange={(e) => {
                                                                        setLogin({...login, password: e.target.value});
                                                                    }}/>
                                                        </CInputGroup>
                                                    </CCol>
                                                </CFormGroup>
                                                <CFormGroup row>
                                                    <CCol className="col-6">
                                                        <CButton type="submit" color="primary"
                                                                 className="px-4">Entrar</CButton>
                                                    </CCol>
                                                </CFormGroup>
                                                <hr className="login-hr"/>
                                                <CFormGroup row>
                                                    <CCol md={4}></CCol>
                                                    <CCol md={4}>
                                                        <div className="text-center">
                                                            <CButton color="link" className="px-0 forgot-password"
                                                                     onClick={() => {
                                                                         history.push("/forgot");
                                                                     }}>¿OLVIDASTE TU CONTRASEÑA?</CButton>
                                                        </div>
                                                    </CCol>
                                                    <CCol md={4}></CCol>
                                                </CFormGroup>
                                                <hr className="login-hr"/>
                                                <CFormGroup row>
                                                    <CCol md={4}></CCol>
                                                    <CCol md={4}>
                                                        <CRow>
                                                            <CCol md="6">
                                                                <p className="text-size login-text-color text-center">REGÍSTRATE</p>
                                                            </CCol>
                                                            <CCol md="6">
                                                                <div className="text-center">
                                                                    <CButton color="primary"
                                                                             onClick={() => {
                                                                                 setShowDonor(!showDonor);
                                                                             }}>AQUI</CButton>
                                                                </div>
                                                            </CCol>
                                                        </CRow>
                                                    </CCol>
                                                    <CCol md={4}></CCol>
                                                </CFormGroup>
                                                <hr className="login-hr"/>
                                            </CForm>
                                        </CCol>
                                    </CRow>
                                    <CRow hidden={!showDonor}>
                                        <CCol>
                                            <CRow>
                                                <CCol>
                                                    <hr className="mt-1 mb-1"/>
                                                </CCol>
                                            </CRow>
                                            <CRow>
                                                <CCol>
                                                    <CForm className="row-bottom" onSubmit={donorSubmit}>
                                                        <CFormGroup row>
                                                            <CCol md="6">
                                                                <CInputGroup className="mb-3">
                                                                    <CInputGroupPrepend>
                                                                        <CInputGroupText>
                                                                            <CIcon name="cil-user"/>
                                                                        </CInputGroupText>
                                                                    </CInputGroupPrepend>
                                                                    <CInput type="text"
                                                                            required
                                                                            placeholder="Nombre"
                                                                            value={donor.name || ''}
                                                                            autoComplete="off"
                                                                            onChange={(e) => {
                                                                                setDonor({...donor, name: e.target.value});
                                                                            }}/>
                                                                </CInputGroup>
                                                            </CCol>
                                                            <CCol md="6">
                                                                <CInputGroup className="mb-3">
                                                                    <CInputGroupPrepend>
                                                                        <CInputGroupText>
                                                                            <CIcon name="cil-user"/>
                                                                        </CInputGroupText>
                                                                    </CInputGroupPrepend>
                                                                    <CInput type="text"
                                                                            required
                                                                            placeholder="Apellidos"
                                                                            value={donor.lastname || ''}
                                                                            autoComplete="off"
                                                                            onChange={(e) => {
                                                                                setDonor({...donor, lastname: e.target.value});
                                                                            }}/>
                                                                </CInputGroup>
                                                            </CCol>
                                                        </CFormGroup>
                                                        <CFormGroup row>
                                                            <CCol md="6">
                                                                <CInputGroup className="mb-3">
                                                                    <CInputGroupPrepend>
                                                                        <CInputGroupText>
                                                                            <CIcon name="cil-user"/>
                                                                        </CInputGroupText>
                                                                    </CInputGroupPrepend>
                                                                    <CInput type="text"
                                                                            required
                                                                            placeholder="Documento de identidad"
                                                                            value={donor.dni || ''}
                                                                            autoComplete="off"
                                                                            onChange={(e) => {
                                                                                setDonor({...donor, dni: e.target.value});
                                                                            }}/>
                                                                    <div className="feedback">Este será su ID de usuario y la contraseña inicial</div>
                                                                </CInputGroup>
                                                            </CCol>
                                                            <CCol md="6">
                                                                <CInputGroup className="mb-3">
                                                                    <DatePicker
                                                                        id="birth"
                                                                        placeholderText="Fecha de nacimiento"
                                                                        dateFormat="dd/MM/yyyy"
                                                                        autoComplete="false"
                                                                        required
                                                                        selected={donor.birth}
                                                                        locale={es}
                                                                        onChange={(date) => setDonor({...donor, birth: date})}
                                                                        className="form-control"
                                                                    />
                                                                </CInputGroup>
                                                            </CCol>
                                                        </CFormGroup>
                                                        <CFormGroup row>
                                                            <CCol md="6">
                                                                <CInputGroup className="mb-4">
                                                                    <CInputGroupPrepend>
                                                                        <CInputGroupText>
                                                                            <CIcon name="cil-envelope-closed"/>
                                                                        </CInputGroupText>
                                                                    </CInputGroupPrepend>
                                                                    <CInput type="email"
                                                                            required
                                                                            placeholder="Correo electrónico"
                                                                            autoComplete="off"
                                                                            value={donor.email || ''}
                                                                            onChange={(e) => {
                                                                                setDonor({...donor, email: e.target.value});
                                                                            }}/>
                                                                </CInputGroup>
                                                            </CCol>
                                                            <CCol md="6">
                                                                <CInputGroup className="mb-4">
                                                                    <CInputGroupPrepend>
                                                                        <CInputGroupText>
                                                                            <CIcon name="cil-phone"/>
                                                                        </CInputGroupText>
                                                                    </CInputGroupPrepend>
                                                                    <CInput type="number"
                                                                            required
                                                                            placeholder="Teléfono"
                                                                            value={donor.phone || ''}
                                                                            autoComplete="off"
                                                                            onChange={(e) => {
                                                                                setDonor({...donor, phone: e.target.value});
                                                                            }}/>
                                                                </CInputGroup>
                                                            </CCol>
                                                        </CFormGroup>
                                                        <CFormGroup row>
                                                            <CCol md="12">
                                                                <CInputGroup className="mb-3">
                                                                    <CInputGroupPrepend>
                                                                        <CInputGroupText>
                                                                            <CIcon name="cil-notes"/>
                                                                        </CInputGroupText>
                                                                    </CInputGroupPrepend>
                                                                    <CTextarea
                                                                        rows="4"  // You can adjust the number of rows as needed
                                                                        required
                                                                        placeholder="Dirección"
                                                                        value={donor.address || ''}
                                                                        autoComplete="off"
                                                                        onChange={(e) => {
                                                                            setDonor({...donor, address: e.target.value});
                                                                        }}/>
                                                                </CInputGroup>
                                                            </CCol>
                                                        </CFormGroup>
                                                        {countryChunks.map((chunk, rowIndex) => (
                                                            <CRow key={rowIndex}>
                                                                {chunk.map((country, colIndex) => (
                                                                    <CCol key={colIndex}>
                                                                        <CFormGroup variant="custom-checkbox"
                                                                                    className="my-2 mt-4">
                                                                            <CInputRadio
                                                                                id={country.id}
                                                                                custom
                                                                                name="countrySelection"
                                                                                onChange={(e) => {
                                                                                    setDonor({
                                                                                        ...donor,
                                                                                        countryId: country.id
                                                                                    });
                                                                                }
                                                                                }
                                                                            />
                                                                            <CLabel variant="custom-checkbox"
                                                                                    htmlFor={country.id}>
                                                                                {country.name}
                                                                            </CLabel>
                                                                        </CFormGroup>
                                                                    </CCol>
                                                                ))}
                                                            </CRow>
                                                        ))}

                                                        <CRow>
                                                            <CCol xs="6">
                                                                <CButton type="submit" color="primary"
                                                                         className="px-4">Solicitar</CButton>
                                                            </CCol>
                                                        </CRow>
                                                    </CForm>
                                                </CCol>
                                            </CRow>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol md={4}></CCol>
                                        <CCol md={4}>
                                            <p className="login-text-color text-center">2024 | Una plataforma de</p>
                                        </CCol>
                                        <CCol md={4}></CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol md={4}></CCol>
                                        <CCol md={4}>
                                            <div className="login">
                                                <CImg style={{height: 50}}
                                                      src={require("../../../assets/images/fundeporte.jpg").default}></CImg>
                                            </div>
                                        </CCol>
                                        <CCol md={4}></CCol>
                                    </CRow>
                                </CCardBody>
                            </CCard>
                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    )
}

export default Login
