import React, {useEffect, useState} from 'react'
import Select from 'react-select';
import {Link, useHistory} from 'react-router-dom';
import {crudService} from "../../_services/crud.service";
import {numberHelper} from "../../_helpers/number.helper";
import { saveAs } from 'file-saver';
import {
    CCard,
    CButton,
    CCardBody,
    CCardHeader,
    CCol,
    CFormGroup,
    CInput,
    CCardFooter,
    CLabel,
    CRow,
    CForm,
    CContainer,
    CInvalidFeedback,
    CDataTable,
    CImg,
    CModal,
    CModalBody,
    CModalHeader,
    CModalFooter, CInputGroupText, CInputGroupPrepend, CInputGroup
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {alertService} from "../../_services/alert.service";
import {hoursHelper} from "../../_helpers/hours.helper";
import {dateHelper} from "../../_helpers/date.helper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import {selectHelper} from "../../_helpers/select.helper";
import AuthProvider from "../../AuthProvider";
import {Role} from "../../_helpers/role";
import {authenticationService} from "../../_services/authentication.service";

let checkCount = 0;
const fields = [
    {key: 'number', label: 'Número', _style: {width: '50%'}},
    {key: 'amount', label: 'Monto', _style: {width: '50%'}},
    {
        key: 'delete',
        label: '',
        _style: {width: '1%'},
        sorter: false,
        filter: false
    }
]

const Sale = () => {
    const history = useHistory();
    const [existingSale, setExistingSale] = useState({});
    const [selectedLotteryId, setSelectedLotteryId] = useState(null);
    const [lotteries, setLotteries] = useState([]);
    const [sales, setSales] = useState([]);
    const [salesAmount, setSalesAmount] = useState(0);
    const [numbers, setNumbers] = useState(0);
    const [selectedNumber, setSelectedNumber] = useState(0);
    const [selectedLottery, setSelectedLottery] = useState({});
    const [isDisabled, setIsDisabled] = useState(true);
    const [disabledControls, setDisabledControls] = useState(false);
    const [projects, setProjects] = useState([]);
    const [sourceImg, setSourceImg] = useState("");
    const [showReceipt, setShowReceipt] = useState(false);
    const [newBillNumber, setNewBillNumber] = useState(null);
    const [isSaleInProcess, setIsSaleInProcess] = useState(false);
    const [saveTextBtn, setSaveTextBtn] = useState("Guardar");
    //const [countryCode, setCountryCode] = useState(null);

    useEffect(() => {
        if (!selectedLotteryId) {
            return;
        }
        crudService.get(`lottery/${selectedLotteryId}`, setSelectedLottery, 'Ocurrió un error obteniendo el sorteo');
    }, [selectedLotteryId]);

    useEffect(() => {
        setNumbers(numberHelper.generateForOptions());
    }, []);

    /*useEffect(() => {
        async function fetchCountryCode() {
            crudService.get('User/getCountryCode', (code) => {
                setCountryCode(code);
            }, 'Ocurrió un error obteniendo el código del país.')
        }

        fetchCountryCode();
    }, []);*/

    useEffect(() => {
        setSales(sales);
    }, [salesAmount]);

    useEffect(() => {
        async function fetchProjects() {
            crudService.get('project/getAllProjects/false', (data) => {
                selectHelper.populateSelectControl(data, "id", "name", (options) => {
                    setProjects(options);
                    if (options.length === 1) {
                        setExistingSale({...existingSale, projectId: options[0].value})
                    }
                });
            }, 'Ocurrió un error listando los proyectos.');
        }

        fetchProjects();
    }, []);

    useEffect(() => {
        async function fetchLotteries() {
            crudService.get(`lottery/getByCountryId`, (data) => {
                selectHelper.populateSelectControl(data, "id",
                    "description",
                    setLotteries);
            }, 'Ocurrió un error listando los sorteos.')
        }

        fetchLotteries();
    }, []);

    function createSale() {
        if (sales.length === 0) {
            return;
        }
        setExistingSale({...existingSale, numbers: sales});
        existingSale.numbers = sales;
        crudService.create('/sale',
            {
                year: existingSale.raffleDate.getFullYear(),
                month: existingSale.raffleDate.getMonth() + 1,
                day: existingSale.raffleDate.getDate(),
                ...existingSale
            },
            (response) => {
                setIsSaleInProcess(true);
                setSaveTextBtn("Procesando...");
                checkCount = 0;
                checkSaleStatus(response.data);
            },
            'Ocurrió un error creando la venta.')
    }

    function checkSaleStatus(saleId) {
        crudService.get(`/sale/isSaleDone/${saleId}`, (exists) => {
            if (exists) {
                alertService.success('La venta se creó correctamente.');
                setIsDisabled(true);
                setNewBillNumber(saleId);
                crudService.get(`/sale/getSaleReceipt/${saleId}`, (receipt) => {
                    setSourceImg(`data:image/png;base64,${receipt}`);
                    setShowReceipt(true);
                    setIsSaleInProcess(false);
                    setSaveTextBtn("Guardar");
                });
            } else {
                if (checkCount < 3) {
                    checkCount++;
                    setTimeout(() => checkSaleStatus(saleId), 3000 * (checkCount + 1));
                } else {
                    setIsSaleInProcess(false);
                    setSaveTextBtn("Guardar");
                    alertService.error('No se pudo verificar si la venta se completó. Puede revisar en unos minutos en la lista de ventas.');
                }
            }
        });
    }

    function addSale() {
        if (existingSale.number !== undefined) {
            const tempSales = [...sales.filter(obj => obj.number === existingSale.number), {
                number: existingSale.number, amount: existingSale.amount
            }];
            
            crudService.post('sale/cansellmore', {
                lotteryId: existingSale.lotteryId,
                year: existingSale.raffleDate.getFullYear(),
                month: existingSale.raffleDate.getMonth() + 1,
                day: existingSale.raffleDate.getDate(),
                numbers: tempSales,
                lines: sales.length + 1
            }, (data) => {
                if (!data.data.canSellMore) {
                    alertService.error(data.data.message)
                    return;
                }

                sales.push({
                    number: existingSale.number,
                    amount: existingSale.amount
                })

                setSalesAmount(oldAmount => parseInt(oldAmount) + parseInt(existingSale.amount));
            }, 'Ocurrió un error agregando el número.');
        }
    }

    function removeSale(item, index) {
        sales.splice(index, 1);
        setSalesAmount(oldAmount => parseInt(oldAmount) - parseInt(item.amount));
        if (sales.length === 0) {
            setDisabledControls(false);
        }
    }

    function validateSale(date) {
        if (selectedLottery.close === undefined) {
            return;
        }
        
        if (!date) {
            setIsDisabled(true);
            return;
        }

        setIsDisabled(true);
        crudService.post('sale/validateSale', {
            lotteryId: selectedLotteryId,
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            day: date.getDate()
        }, (data) => {
            if (!data.data.isValid) {
                setIsDisabled(true);
                alertService.error(`No se puede crear la venta. El sorteo ya cerró.`)
                return;
            }
            setIsDisabled(false);
        }, 'Ocurrió un error validando la venta.');
    }

    const renderRestrictionsForm = () => {
        const handleSubmit = event => {
            event.preventDefault();

            const form = event.currentTarget;

            if (form.checkValidity()) {
                if (!existingSale.projectId) {
                    alertService.error("El proyecto es requerido.");
                    return;
                }

                if (!existingSale.lotteryId) {
                    alertService.error("El sorteo es requerido.");
                    return;
                }
                addSale();
                setDisabledControls(true);
            }
        }
        return (
            <AuthProvider roles={[Role.Admin, Role.Supervisor, Role.Seller, Role.Donor]}>
                <CContainer fluid>
                    <CRow>
                        <CCol lg={6}>
                            <CCard>
                                <CCardHeader>
                                    <CRow>
                                        <CCol lg={6}>
                                            <strong>{'RECOLECTAR'}</strong>
                                        </CCol>
                                        <CCol lg={6} className="float-right">
                                            <strong>Hora cierre: {hoursHelper.toAMPM(selectedLottery.close)}</strong>
                                        </CCol>
                                    </CRow>
                                </CCardHeader>
                                <CCardBody>
                                    <CForm className="was-validated"
                                           onSubmit={handleSubmit}>
                                        <CFormGroup>
                                            <CLabel htmlFor="project">Proyecto</CLabel>
                                            <Select
                                                required
                                                isDisabled={disabledControls || projects.length === 1}
                                                value={projects.filter(option => option.value === existingSale.projectId)}
                                                onChange={(e) => {
                                                    setExistingSale({...existingSale, projectId: e.value});
                                                }}
                                                placeholder={"Seleccione un proyecto"}
                                                noOptionsMessage={() => "No hay proyectos"}
                                                options={projects}/>
                                            <CInvalidFeedback invalid="false">El proyecto es requerido.</CInvalidFeedback>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="lottery">Sorteo</CLabel>
                                            <Select
                                                required
                                                value={lotteries.filter(option => option.value === selectedLotteryId)}
                                                isDisabled={disabledControls}
                                                onChange={(e) => {
                                                    setExistingSale({...existingSale, lotteryId: e.value});
                                                    setSelectedLotteryId(e.value);
                                                    setDisabledControls(false);
                                                    validateSale(existingSale.raffleDate);
                                                }}
                                                placeholder={"Seleccione un sorteo"}
                                                noOptionsMessage={() => "No hay sorteos"}
                                                options={lotteries}/>
                                            <CInvalidFeedback invalid="false">El sorteo es requerido.</CInvalidFeedback>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="raffleDate">Fecha</CLabel>
                                            <DatePicker
                                                id="close"
                                                dateFormat="dd/MM/yyyy"
                                                disabled={disabledControls}
                                                selected={existingSale.raffleDate}
                                                autoComplete="false"
                                                locale={es}
                                                required
                                                onChange={(raffleDate) => {
                                                    setExistingSale({...existingSale, raffleDate: raffleDate});
                                                    validateSale(raffleDate);
                                                }}
                                                className="form-control"
                                            />
                                            <CInvalidFeedback invalid="false">La fecha es requerido.</CInvalidFeedback>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="client">Cliente</CLabel>
                                            <CInput
                                                type="text"
                                                required
                                                autoComplete="false"
                                                disabled={disabledControls || authenticationService.isDonor}
                                                id="client"
                                                defaultValue={authenticationService.isDonor ? authenticationService.getUserFullName : existingSale.client}
                                                onChange={(e) => {
                                                    setExistingSale({...existingSale, client: e.target.value});
                                                }}/>
                                            <CInvalidFeedback invalid="false">El cliente es requerida.</CInvalidFeedback>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="phoneNumber">Número teléfono</CLabel>
                                            <CInput
                                                required
                                                type="number"
                                                autoComplete="false"
                                                disabled={disabledControls || authenticationService.isDonor}
                                                id="phoneNumber"
                                                defaultValue={authenticationService.isDonor ? authenticationService.getUserPhoneNumber : existingSale.phoneNumber}
                                                onChange={(e) => {
                                                    setExistingSale({...existingSale, phoneNumber: e.target.value});
                                                }}/>
                                            <CInvalidFeedback invalid="false">El número de teléfono es
                                                requerido.</CInvalidFeedback>
                                        </CFormGroup>
                                        {/*<CFormGroup>
                                            <CLabel htmlFor="phoneNumber">Número teléfono</CLabel>
                                            <CInputGroup className="input-prepend">
                                                <CInputGroupPrepend>
                                                    <CInputGroupText>{countryCode}</CInputGroupText>
                                                </CInputGroupPrepend>
                                                <CInput
                                                    type="text"
                                                    required
                                                    autoComplete="false"
                                                    disabled={disabledControls}
                                                    id="phoneNumber"
                                                    defaultValue={existingSale.phoneNumber}
                                                    onChange={(e) => {
                                                        setExistingSale({...existingSale, phoneNumber: e.target.value});
                                                    }}/>
                                                <CInvalidFeedback invalid="false">El número de teléfono es
                                                    requerido.</CInvalidFeedback>
                                            </CInputGroup>
                                        </CFormGroup>*/}
                                        
                                        <CFormGroup>
                                            <CLabel htmlFor="number">Número</CLabel>
                                            <Select
                                                required
                                                defaultValue={selectedNumber}
                                                onChange={(e) => {
                                                    setExistingSale({...existingSale, number: e.value});
                                                    setSelectedNumber(e.value);
                                                }}
                                                placeholder={"Seleccione un número"}
                                                noOptionsMessage={() => "No hay números"}
                                                options={numbers}/>
                                            <CInvalidFeedback invalid="false">El número es requerido.</CInvalidFeedback>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="close">Monto</CLabel>
                                            <CInput required
                                                    type="number"
                                                    autoComplete="false"
                                                    id="close"
                                                    min="0"
                                                    defaultValue={existingSale.amount}
                                                    onChange={(e) => {
                                                        setExistingSale({
                                                            ...existingSale,
                                                            amount: e.target.value
                                                        });
                                                    }}/>
                                            <CInvalidFeedback invalid="false">El monto es requerido.</CInvalidFeedback>
                                        </CFormGroup>
                                        <CCardFooter>
                                            <CButton type="submit" disabled={isDisabled} size="md" color="primary"
                                                     className="mr-2">+ Agregar</CButton>
                                            <Link to='/list' className="mr-2 btn btn-link">Cancelar</Link>
                                        </CCardFooter>
                                    </CForm>
                                </CCardBody>
                            </CCard>
                        </CCol>
                        <CCol lg={6}>
                            <CCard>
                                <CCardHeader>
                                    <strong>{'RECOLECTADO'}</strong>
                                </CCardHeader>
                                <CCardBody>
                                    <CDataTable
                                        items={sales}
                                        fields={fields}
                                        columnFilter
                                        noItemsView={{noResults: 'No hay números', noItems: 'No hay números'}}
                                        tableFilter={{placeholder: 'Buscar', label: 'Buscar:'}}
                                        footer
                                        itemsPerPageSelect={{label: 'Filas por página'}}
                                        itemsPerPage={5}
                                        hover
                                        sorter
                                        pagination
                                        scopedSlots={{
                                            'delete': (item, index) => {
                                                return (
                                                    <td className="py-2">
                                                        <CButton
                                                            color="danger"
                                                            variant="outline"
                                                            shape="square"
                                                            size="sm"
                                                            hidden={newBillNumber !== null}
                                                            onClick={() => {
                                                                removeSale(item, index);
                                                            }}
                                                        >
                                                            Eliminar
                                                        </CButton>
                                                    </td>
                                                )
                                            }
                                        }}
                                    />
                                </CCardBody>
                                <CCardFooter>
                                    <CRow>
                                        <CCol lg={6}>
                                            <strong>Total: {salesAmount}</strong>
                                        </CCol>
                                        <CCol lg={6}>
                                            <CButton type="button" size="md" color="primary" hidden={!newBillNumber} className="mr-2 float-right"
                                                     onClick={() => {
                                                         setShowReceipt(!showReceipt);
                                                     }}>
                                                <CIcon name="cil-save"/> Ver tiquete</CButton>
                                            <CButton type="button" size="md" color="primary" disabled={newBillNumber !== null || isSaleInProcess} className="mr-2 float-right"
                                                     onClick={() => {
                                                         createSale();
                                                     }}>
                                                <CIcon name="cil-save"/> {saveTextBtn}</CButton>
                                        </CCol>
                                    </CRow>
                                </CCardFooter>
                            </CCard>
                            <CCol lg={12}>
                                <CButton type="button" size="md" color="primary" disabled={newBillNumber === null} className="mr-2 float-right"
                                    onClick={() => {
                                        history.push("/sale");
                                    }}>
                                    <CIcon name="cil-save" /> Seguir recolectando</CButton>
                            </CCol>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol lg={12}>
                            <CModal
                                show={showReceipt}
                                onClose={() => {
                                    setShowReceipt(!showReceipt);
                                }}>
                                <CModalHeader closeButton>Tiquete</CModalHeader>
                                <CModalBody>
                                    <CRow>
                                        <CCol lg={12} className="text-center">
                                            <CImg src={sourceImg} fluid className="img-thumbnail"></CImg>
                                        </CCol>
                                    </CRow>
                                </CModalBody>
                                <CModalFooter>
                                    <CButton color="primary" onClick={() => {
                                        saveAs(`/sale/getSaleReceiptDownload/${newBillNumber}`, `${newBillNumber}.jpg`);
                                        window.open(`https://api.whatsapp.com/send?phone=${existingSale.phoneNumber}`, '_blank', 'noreferrer');
                                    }}>Enviar WhatsApp</CButton>{' '}
                                    <CButton color="primary" onClick={() => {
                                        window.open(`/sale/getSaleReceiptDownload/${newBillNumber}`, '_blank', 'noreferrer');
                                    }}>Imprimir</CButton>{' '}
                                    <CButton color="secondary" onClick={() => {
                                        setShowReceipt(!showReceipt);
                                    }}>Cerrar</CButton>
                                </CModalFooter>
                            </CModal>
                        </CCol>
                    </CRow>
                </CContainer>
            </AuthProvider>
        );
    }

    return (renderRestrictionsForm())
}

export default Sale