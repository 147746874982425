import React, {useEffect, useState} from 'react'
import {Link, useHistory} from 'react-router-dom';
import {crudService} from "../../_services/crud.service";
import {
    CCard,
    CButton,
    CCardBody,
    CCardHeader,
    CCol,
    CFormGroup,
    CInput,
    CCardFooter,
    CLabel,
    CRow,
    CForm,
    CContainer,
    CInvalidFeedback
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {alertService} from "../../_services/alert.service";
import Select from "react-select";
import {selectHelper} from "../../_helpers/select.helper";
import {authenticationService} from "../../_services/authentication.service";

const Recharge = ({match}) => {
    const history = useHistory();
    const [existingRecharge, setRecharge] = useState({});
    const [paymentMethods, setPaymentMethods] = useState([]);
    
    function createRecharge() {
        crudService.create('/recharge/recharge',
            existingRecharge,
            (response) => {
                alertService.success('La solicitud de recarga se creó correctamente. Espero a que sea aprobada.');
                setRecharge(response.data);
                history.push('/sales')
            },
            'Ocurrió un error creando la recarga.')
    }

    useEffect(() => {
        async function fetchPaymentMethods() {
            crudService.get('/country/getCurrentCountryPaymentMethods', (data) => {
                selectHelper.populateSelectControl(data, "paymentMethodName", "paymentMethodName", setPaymentMethods);
            }, 'Ocurrió un error listando los métodos de pago.');
        }

        fetchPaymentMethods();
    }, []);

    const renderRechargeForm = () => {
        const handleSubmit = event => {
            event.preventDefault();

            const form = event.currentTarget;

            if (form.checkValidity()) {
                createRecharge();
            }
        }
        return (
            <CContainer fluid>
                <CRow>
                    <CCol lg={10}>
                        <CCard>
                            <CCardHeader>
                                <strong>{'Recargar'}</strong>
                                <strong className="float-right">{
                                    authenticationService.isPeru 
                                        ? 'Yape: 982303698 | Cta. BCP CCI: 002-335-009972005059-82' 
                                        : authenticationService.isCr 
                                            ? 'Sinpe Móvil: 62973737 | IBAN BNCR: CR4201510531001001571' 
                                            : ''}</strong>
                            </CCardHeader>
                            <CCardBody>
                                <CForm className="was-validated"
                                       onSubmit={handleSubmit}>
                                    <CFormGroup>
                                        <CLabel htmlFor="paymentMethod">Método de pago</CLabel>
                                        <Select
                                            required
                                            defaultValue={existingRecharge.paymentMethod}
                                            onChange={(e) => {
                                                setRecharge({...existingRecharge, paymentMethod: e.value});
                                            }}
                                            id="paymentMethod"
                                            placeholder={"Seleccione un método de pago"}
                                            noOptionsMessage={() => "No hay métodos de pago para este país."}
                                            options={paymentMethods}/>
                                        <CInvalidFeedback>El método de pago es requerido.</CInvalidFeedback>
                                    </CFormGroup>
                                    <CFormGroup>
                                        <CLabel htmlFor="transactionNumber"># de transacción</CLabel>
                                        <CInput type="text"
                                                autoComplete="false"
                                                id="transactionNumber"
                                                defaultValue={existingRecharge.transactionNumber}
                                                onChange={(e) => {
                                                    setRecharge({...existingRecharge, transactionNumber: e.target.value});
                                                }}
                                        />
                                        <CInvalidFeedback>El método de pago es requerido.</CInvalidFeedback>
                                    </CFormGroup>
                                    <CFormGroup>
                                        <CLabel htmlFor="phoneNumber">Teléfono</CLabel>
                                        <CInput type="text"
                                                autoComplete="false"
                                                required
                                                id="phoneNumber"
                                                defaultValue={existingRecharge.phoneNumber}
                                                onChange={(e) => {
                                                    setRecharge({...existingRecharge, phoneNumber: e.target.value});
                                                }}
                                        />
                                        <CInvalidFeedback>El teléfono es requerido.</CInvalidFeedback>
                                    </CFormGroup>
                                    <CFormGroup>
                                        <CLabel htmlFor="description">Monto</CLabel>
                                        <CInput type="number"
                                                autoComplete="false"
                                                required
                                                id="amount"
                                                defaultValue={existingRecharge.amount}
                                                onChange={(e) => {
                                                    setRecharge({...existingRecharge, amount: e.target.value});
                                                }}
                                        />
                                        <CInvalidFeedback>El monto es requerido.</CInvalidFeedback>
                                    </CFormGroup>
                                    <CCardFooter>
                                        <CButton type="submit" size="md" color="primary" className="mr-2"><CIcon
                                            name="cil-save"/> Guardar</CButton>
                                        <Link to='/sales' className="btn btn-link">Cancelar</Link>
                                    </CCardFooter>
                                </CForm>
                            </CCardBody>
                        </CCard>
                    </CCol>
                </CRow>
            </CContainer>
        );
    }

    return (renderRechargeForm(existingRecharge))
}

export default Recharge
