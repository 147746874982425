import React from 'react'
import {CCol, CContainer, CFooter, CImg, CRow} from '@coreui/react'

const TheFooter = () => {
    return (
        <CFooter fixed={false}>
            <CContainer>
                <CRow>
                    <CCol md={4}></CCol>
                    <CCol md={4}>
                        <div className="d-sm-down-none">
                            <CImg style={{height: 80}} src={require("../assets/images/fundeporte2.jpg").default}></CImg>
                        </div>
                    </CCol>
                    <CCol md={4}></CCol>
                </CRow>
                <CRow>
                    <CCol md={12}>
                        <div>
                            <a href="https://en.fundeporte.org/" target="_blank" rel="noopener noreferrer">Fundeporte</a>
                            <span>&copy; Anota y Gana TV es una dinámica interactiva de recaudación de fondos para la realización de programas y proyectos sociales
                    de Fundación Deporte Latinoamérica. 2022.</span>
                        </div>
                    </CCol>
                </CRow>
            </CContainer>
        </CFooter>
    )
}

export default React.memo(TheFooter)
