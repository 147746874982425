import React from 'react'
import {authenticationService} from "../_services/authentication.service";

const _nav =  [
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Ventas']
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Vender',
    to: '/sales',
    icon: 'cil-money'
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Lista',
    to: '/list',
    icon: 'cil-list-numbered',
  },
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Configuración'],
    hidden: !authenticationService.isManager
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Configuración',
    route: '#',
    icon: 'cil-settings',
    hidden: !authenticationService.isManager,
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Usuarios',
        to: '/users',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Sorteos',
        to: '/lotteries',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Restricciones',
        to: '/restrictions',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Proyectos',
        to: '/projects',
      }
    ],
  },
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Reportes']
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Reporte 1',
    to: '/sales',
    icon: 'cil-money',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Reporte 2',
    to: '/list',
    icon: 'cil-list-numbered',
  },
  {
    _tag: 'CSidebarNavDivider',
    className: 'm-2'
  }
]

export default _nav
